<template>
    <div class="axil-signin-area">
        <!-- Start Header -->
        <div class="signin-header">
            <div class="row align-items-center">
                <div class="col-md-6">
                    <router-link to="/" class="site-logo"
                        ><img
                            src="/logo.png"
                            alt="logo"
                            style="max-height: 100px"
                    /></router-link>
                </div>
                <div class="col-md-6">
                    <div class="singin-header-btn">
                        <p>{{ $t("Van már fiókod?") }}</p>
                        <router-link
                            to="/login"
                            class="axil-btn btn-bg-secondary sign-up-btn"
                            >{{ $t("Jelentkezz be") }}</router-link
                        >
                    </div>
                </div>
            </div>
        </div>
        <!-- End Header -->

        <div class="row">
            <div class="col-xl-4 col-lg-6">
                <div
                    class="axil-signin-banner bg_image bg_image--10"
                    style="background: url('/ragavan.png'); background-repeat: no-repeat; background-size: cover;"
                >
                    <h3 class="title" style="color: white">
                        {{
                            $t(
                                "Magic Singles - Magyarország legnagyobb Online Mtg Single Webshopja"
                            )
                        }}
                    </h3>
                </div>
            </div>
            <div class="col-lg-6 offset-xl-2">
                <div class="axil-signin-form-wrap">
                    <div class="axil-signin-form">
                        <h3 class="title">{{ $t("Regisztráció") }}</h3>
                        <p class="b2 mb--55">
                            {{ $t("Add meg az adataidat") }}
                        </p>
                        <form
                            class="singin-form"
                            v-on:submit.prevent="onSubmit"
                        >
                            <div class="form-group">
                                <label>{{ $t("Név") }}</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    name="username"
                                    v-model="form.name"
                                    value="anniemario"
                                />
                            </div>
                            <div class="form-group">
                                <label>{{ $t("Email") }}</label>
                                <input
                                    type="email"
                                    class="form-control"
                                    name="email"
                                    v-model="form.email"
                                    value="annie@example.com"
                                />
                            </div>
                            <div class="form-group">
                                <label>{{ $t("Jelszó") }}</label>
                                <input
                                    type="password"
                                    class="form-control"
                                    name="password"
                                    v-model="form.password"
                                    value="123456789"
                                />
                            </div>
                            <div class="form-group input-group">
                                <input
                                    type="checkbox"
                                    id="aszf"
                                    name="terms-privacy"
                                    v-model="form.aszf"
                                />
                                <label for="aszf">{{
                                    $t(
                                        "Elfogadom az adatvédelmi nyilatkozatot és az általános szerződési feltételeket."
                                    )
                                }}</label>
                            </div>
                            <div class="form-group">
                                <button
                                    type="submit"
                                    class="axil-btn btn-bg-primary submit-btn"
                                >
                                    {{ $t("Fiók létrehozása") }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import store from "@/core/services/store";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";

export default {
    name: "Registration",
    data() {
        return {
            form: {
                email: null,
                password: null,
                firstname: null,
                lastname: null,
                username: null,
                aszf: null,
                name: null
            }
        };
    },
    beforeMount() {
        Promise.all([store.dispatch(VERIFY_AUTH)]).then(res => {
            console.log(res);
            //this.$router.push({ name: "Home" });
        });
    },
    methods: {
        onSubmit() {
            const self = this;

            ApiService.post(`register`, this.form).then(resp => {
                JwtService.saveToken(resp.data.token);
                self.form = {
                    email: null,
                    password: null,
                    firstname: null,
                    lastname: null,
                    username: null,
                    aszf: null,
                    name: null
                };

                self.$gtag.event("sign_up", {
                    event_category: "engagement",
                    event_label: "sign_up",
                    value: self.form.email
                });

                self.$cookies.set("id_token", resp.data.token, 86400);
                self.$router.push({ name: "Index" });
            });
        }
    }
};
</script>

<style scoped></style>
